import React from 'react';
import PropTypes from 'prop-types';
import ChatLeft from '../atoms/chatLeft';
import { ChatImage, ChatVideo } from '../atoms/chatMedia';
import { ChatRichtextSanity } from '../atoms/chatRichtext';
import { ChatInfo, ChatLink } from '../atoms/chatText';

const Kapitel = ({ data, name, padding = true }) => (
  <>
    {data && (
      <>
        <div style={{ paddingTop: padding ? 'var(--v-spacing-XL)' : 0 }} />
        <ChatLeft text={name} />
      </>
    )}
    {data?.map(
      (item) =>
        (item.__typename === 'SanityLink' && (
          <ChatLink link={item.url} text={item.text} />
        )) ||
        (item.__typename === 'SanityCopy' && (
          <ChatRichtextSanity left blocks={item._rawCopy} />
        )) ||
        (item.__typename === 'SanityMainImage' && (
          <ChatImage imgSrc={item.image.asset.fluid} imgAlt={item.alt} />
        )) ||
        (item.__typename === 'SanityInfo' && <ChatInfo text={item.info} />) ||
        (item.__typename === 'SanityVideo' && <ChatVideo videoSrc={item.url} />)
    )}
  </>
);

Kapitel.propTypes = {
  data: PropTypes.node,
  name: PropTypes.string,
  padding: PropTypes.bool,
};

export default Kapitel;
