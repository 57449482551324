import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { media } from '../../utils/media-queries';

/**
 * ILLU
 */
export const ChatIllu = ({ children, stored, key }) => (
  <StyledChatIllu
    key={key}
    initial={{ y: stored ? 0 : 500 }}
    animate={{ y: stored ? 500 : 0 }}
  >
    {/* SVG goes here */}
    {children}
  </StyledChatIllu>
);

const StyledChatIllu = styled.div`
  margin: var(--v-spacing-S) var(--v-spacing-S) 0 13vw;

  svg {
    width: auto;
    height: 42vh;
  }
`;

ChatIllu.propTypes = {
  children: PropTypes.node,
  stored: PropTypes.bool,
  key: PropTypes.string,
};

/**
 * IMAGE
 */
export const ChatImage = ({ imgSrc, imgAlt, key }) => (
  <StyledChatImage key={key}>
    <Img fluid={imgSrc} alt={imgAlt} />
  </StyledChatImage>
);

const StyledChatImage = styled.figure`
  border: var(--border);
  margin: calc(var(--v-spacing-S) / 2) var(--v-spacing-S);

  .gatsby-image-wrapper {
    width: 75vw;
    height: 75vw;
  }

  @media ${media.M} {
    .gatsby-image-wrapper {
      width: 45vw;
      height: 45vw;
    }
  }
`;

ChatImage.propTypes = {
  imgSrc: PropTypes.object,
  imgAlt: PropTypes.string,
  key: PropTypes.string,
};

/**
 * VIDEO
 */
export const ChatVideo = ({ videoSrc, key }) => {
  const YTId = videoSrc.split('?v=')[1];
  const generateYTURL = `https://www.youtube-nocookie.com/embed/${YTId}`;

  return (
    <StyledChatVideo key={key}>
      <iframe
        src={
          videoSrc.includes('youtube')
            ? `${generateYTURL}`
            : `${videoSrc}?dnt=1`
        }
        title="Video"
        frameBorder="0"
        webkitallowfullscreen="true"
        mozallowfullscreen="true"
        allowFullScreen
      />
    </StyledChatVideo>
  );
};

const StyledChatVideo = styled.div`
  border: var(--border);
  margin: calc(var(--v-spacing-S) / 2) var(--v-spacing-S);
  position: relative;
  padding-top: 42.1%;
  overflow: hidden;
  width: 75vw;
  height: auto;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  @media ${media.M} {
    padding-top: 36.5%;
    width: 65vw;
  }
`;

ChatVideo.propTypes = {
  videoSrc: PropTypes.string,
  key: PropTypes.string,
};
