import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';

const ChatLeft = ({ text, stored = false, time, key }) => {
  const [endLoading, setEndLoading] = useState(false);

  useEffect(() => {
    const timer = setTimeout(
      () => {
        setEndLoading(true);
      },
      stored ? 0 : time
    );
    return () => clearTimeout(timer);
  }, [time, stored]);

  return (
    <StyledChat
      key={key}
      className="spacing-outer"
      initial={{ scale: stored ? 1 : 0 }}
      animate={{ scale: 1 }}
    >
      <p>{endLoading || stored ? text : <Loading />}</p>
    </StyledChat>
  );
};

const StyledChat = styled(motion.div)`
  border: var(--border);
  background: var(--ee-color);
  max-width: 90%;
`;

ChatLeft.propTypes = {
  text: PropTypes.string,
  time: PropTypes.number,
  stored: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
    PropTypes.string,
  ]),
};

const LoadingDot = ({ delay }) => (
  <motion.span
    animate={{ opacity: [0, 1, 0] }}
    transition={{ delay, repeat: Infinity, repeatDelay: 0.4 }}
  >
    .
  </motion.span>
);

const Loading = () => (
  <>
    <LoadingDot delay={0.2} />
    <LoadingDot delay={0.4} />
    <LoadingDot delay={0.6} />
  </>
);

LoadingDot.propTypes = {
  delay: PropTypes.number,
};

export default ChatLeft;
