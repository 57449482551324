import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Right from '../../assets/svg/right.svg';
import { media } from '../../utils/media-queries';

export const ChatInfo = ({ stored = false, text, key }) => (
  <StyledChatInfo
    key={key}
    className="spacing-outer-left"
    initial={{ scale: stored ? 1 : 0 }}
    animate={{ scale: 1 }}
  >
    <p>{text}</p>
  </StyledChatInfo>
);

const StyledChatInfo = styled(motion.div)`
  border: var(--border);
  background: var(--ee-color);
  max-width: 100%;
  p {
    font-family: var(--font-2);
    font-size: var(--fontsize-2);
    display: block;
  }

  @media ${media.M} {
    max-width: 90%;
  }
`;

ChatInfo.propTypes = {
  stored: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
    PropTypes.string,
  ]),
  text: PropTypes.string,
  key: PropTypes.string,
};

export const ChatLink = ({ text, stored = false, link, internalLink }) => (
  <StyledChatLink
    className="spacing-outer-left"
    initial={{ y: stored ? 0 : 500 }}
    animate={{ y: 0 }}
  >
    {internalLink ? (
      <Link to={internalLink}>
        <p>
          {text} <Right />
        </p>
      </Link>
    ) : (
      <a href={link} target="_blank" rel="noreferrer">
        <p>
          {text} <Right />
        </p>
      </a>
    )}
  </StyledChatLink>
);

const StyledChatLink = styled(motion.div)`
  border: var(--border);
  background: var(--user-color);
  max-width: 100%;

  &:hover {
    svg {
      transform: rotate(-45deg);
    }
  }

  svg {
    transition: all 0.1s ease-out;
    height: calc(var(--fontsize-1) / 1.4);
    width: auto;
    padding-left: var(--spacing-XXS);
  }

  @media ${media.M} {
    max-width: 65%;
  }
`;

ChatLink.propTypes = {
  link: PropTypes.string,
  text: PropTypes.string,
  stored: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
    PropTypes.string,
  ]),
  internalLink: PropTypes.string,
};

export default ChatLink;

export const ChatNumber = ({ text, id, key }) => (
  <StyledChatNumber id={id} key={key} className="spacing-outer">
    <p>{text}</p>
  </StyledChatNumber>
);

const StyledChatNumber = styled.div`
  border: var(--border);
  background: var(--ee-color);
  p {
    font-family: var(--font-1);
    font-size: var(--fontsize-2);
    display: block;
  }
`;

ChatNumber.propTypes = {
  text: PropTypes.string,
  id: PropTypes.string,
  key: PropTypes.string,
};
