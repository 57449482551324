import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Layout from '../components/organisms/layout';
import SEO from '../components/organisms/seo';
import { ChatLink } from '../components/atoms/chatText';
import ChatLeft from '../components/atoms/chatLeft';
import Kapitel from '../components/molecules/kapitel';
import { useClientSide } from '../hooks/useClientSide';
import { ChatRichtext } from '../components/atoms/chatRichtext';

const BuchPage = ({ data }) => {
  const isClient = useClientSide();

  const { buch } = data.gradients.nodes[0];

  useEffect(() => {
    document.body.classList.add('white');
    return () => document.body.classList.remove('white');
  });

  const intro = data.intro.nodes[0]?.content;
  const sonne = data.sonne.nodes[0]?.content;
  const biomasse = data.biomasse.nodes[0]?.content;
  const wind = data.wind.nodes[0]?.content;
  const wasser = data.wasser.nodes[0]?.content;
  const wellenkraft = data.wellenkraft.nodes[0]?.content;
  const gezeiten = data.gezeiten.nodes[0]?.content;
  const geothermie = data.geothermie.nodes[0]?.content;
  const techniken = data.techniken.nodes[0]?.content;
  const speicher = data.speicher.nodes[0]?.content;
  const kernkraft = data.kernkraft.nodes[0]?.content;

  const titleintro = data.intro.nodes[0]?.title;
  const titlesonne = data.sonne.nodes[0]?.title;
  const titlebiomasse = data.biomasse.nodes[0]?.title;
  const titlewind = data.wind.nodes[0]?.title;
  const titlewasser = data.wasser.nodes[0]?.title;
  const titlewellenkraft = data.wellenkraft.nodes[0]?.title;
  const titlegezeiten = data.gezeiten.nodes[0]?.title;
  const titlegeothermie = data.geothermie.nodes[0]?.title;
  const titletechniken = data.techniken.nodes[0]?.title;
  const titlespeicher = data.speicher.nodes[0]?.title;
  const titlekernkraft = data.kernkraft.nodes[0]?.title;

  const path = isClient && String(window.location);
  const [enteredNumber, setEnteredNumber] = useState();
  const kapitel = Object.keys(data);

  useEffect(() => {
    kapitel.map(
      (item, i) => path.includes(`#${i + 1}`) && setEnteredNumber(`#${i + 1}`)
    );

    const section = document.getElementById(enteredNumber);
    return section?.scrollIntoView({ behavior: 'smooth' });
  });

  return (
    <Layout bgSrc={buch.image.asset.fluid}>
      <SEO siteTitle="Das Buch" />
      <div className="chats" style={{ paddingBottom: 'var(--spacing-L)' }}>
        <Kapitel data={intro} name={titleintro} padding={false} />
        <Kapitel data={sonne} name={titlesonne} />
        <Kapitel data={biomasse} name={titlebiomasse} />
        <Kapitel data={wind} name={titlewind} />
        <Kapitel data={wasser} name={titlewasser} />
        <Kapitel data={wellenkraft} name={titlewellenkraft} />
        <Kapitel data={gezeiten} name={titlegezeiten} />
        <Kapitel data={geothermie} name={titlegeothermie} />
        <Kapitel data={techniken} name={titletechniken} />
        <Kapitel data={speicher} name={titlespeicher} />
        <Kapitel data={kernkraft} name={titlekernkraft} />
        <div style={{ paddingTop: 'var(--v-spacing-XL)' }} />
        <ChatLink stored internalLink="/drumherum/" text="Alles Drumherum" />
      </div>
    </Layout>
  );
};

export const query = graphql`
  query Buch {
    gradients: allSanityGradients {
      nodes {
        buch {
          alt
          image {
            asset {
              fluid(maxWidth: 3600) {
                ...GatsbySanityImageFluid_noBase64
              }
            }
          }
        }
      }
    }
    intro: allSanityIntro {
      nodes {
        title
        content {
          ... on SanityInfo {
            info
          }
          ... on SanityCopy {
            _rawCopy
          }
          ... on SanityLink {
            text
            url
          }
          ... on SanityMainImage {
            alt
            image {
              asset {
                fluid(maxWidth: 3600) {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
          ... on SanityVideo {
            url
          }
        }
      }
    }
    sonne: allSanitySonne {
      nodes {
        title
        content {
          ... on SanityInfo {
            info
          }
          ... on SanityCopy {
            _rawCopy
          }
          ... on SanityLink {
            text
            url
          }
          ... on SanityMainImage {
            alt
            image {
              asset {
                fluid(maxWidth: 3600) {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
          ... on SanityVideo {
            url
          }
        }
      }
    }
    biomasse: allSanityBiomasse {
      nodes {
        title
        content {
          ... on SanityInfo {
            info
          }
          ... on SanityCopy {
            _rawCopy
          }
          ... on SanityLink {
            text
            url
          }
          ... on SanityMainImage {
            alt
            image {
              asset {
                fluid(maxWidth: 3600) {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
          ... on SanityVideo {
            url
          }
        }
      }
    }
    wind: allSanityWind {
      nodes {
        title
        content {
          ... on SanityInfo {
            info
          }
          ... on SanityCopy {
            _rawCopy
          }
          ... on SanityLink {
            text
            url
          }
          ... on SanityMainImage {
            alt
            image {
              asset {
                fluid(maxWidth: 3600) {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
          ... on SanityVideo {
            url
          }
        }
      }
    }
    wasser: allSanityWasser {
      nodes {
        title
        content {
          ... on SanityInfo {
            info
          }
          ... on SanityCopy {
            _rawCopy
          }
          ... on SanityLink {
            text
            url
          }
          ... on SanityMainImage {
            alt
            image {
              asset {
                fluid(maxWidth: 3600) {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
          ... on SanityVideo {
            url
          }
        }
      }
    }
    wellenkraft: allSanityWellenkraft {
      nodes {
        title
        content {
          ... on SanityInfo {
            info
          }
          ... on SanityCopy {
            _rawCopy
          }
          ... on SanityLink {
            text
            url
          }
          ... on SanityMainImage {
            alt
            image {
              asset {
                fluid(maxWidth: 3600) {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
          ... on SanityVideo {
            url
          }
        }
      }
    }
    gezeiten: allSanityGezeiten {
      nodes {
        title
        content {
          ... on SanityInfo {
            info
          }
          ... on SanityCopy {
            _rawCopy
          }
          ... on SanityLink {
            text
            url
          }
          ... on SanityMainImage {
            alt
            image {
              asset {
                fluid(maxWidth: 3600) {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
          ... on SanityVideo {
            url
          }
        }
      }
    }
    geothermie: allSanityGeothermie {
      nodes {
        title
        content {
          ... on SanityInfo {
            info
          }
          ... on SanityCopy {
            _rawCopy
          }
          ... on SanityLink {
            text
            url
          }
          ... on SanityMainImage {
            alt
            image {
              asset {
                fluid(maxWidth: 3600) {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
          ... on SanityVideo {
            url
          }
        }
      }
    }
    techniken: allSanityTechniken {
      nodes {
        title
        content {
          ... on SanityInfo {
            info
          }
          ... on SanityCopy {
            _rawCopy
          }
          ... on SanityLink {
            text
            url
          }
          ... on SanityMainImage {
            alt
            image {
              asset {
                fluid(maxWidth: 3600) {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
          ... on SanityVideo {
            url
          }
        }
      }
    }
    speicher: allSanitySpeicher {
      nodes {
        title
        content {
          ... on SanityInfo {
            info
          }
          ... on SanityCopy {
            _rawCopy
          }
          ... on SanityLink {
            text
            url
          }
          ... on SanityMainImage {
            alt
            image {
              asset {
                fluid(maxWidth: 3600) {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
          ... on SanityVideo {
            url
          }
        }
      }
    }
    kernkraft: allSanityKernkraft {
      nodes {
        title
        content {
          ... on SanityInfo {
            info
          }
          ... on SanityCopy {
            _rawCopy
          }
          ... on SanityLink {
            text
            url
          }
          ... on SanityMainImage {
            alt
            image {
              asset {
                fluid(maxWidth: 3600) {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
          ... on SanityVideo {
            url
          }
        }
      }
    }
  }
`;

BuchPage.propTypes = {
  data: PropTypes.object,
};

export default BuchPage;
